import React, { useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import "./css/footer.css";
import { NavLink } from "react-bootstrap";
import cna from '../../assets/images/cna_chile.png';

const Footer = () => {
  const [btnState, setBtnState] = useState(false);

  function handleClick() {
    setBtnState((btnState) => !btnState);
  }

  let social_reveal = btnState ? "_active" : "";

  return (
    <>
      <ScrollToTop smooth />
      <footer className="text-center text-lg-start bg-body-tertiary text-white footer_background">
        <div className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block align-self-center">
            <span>Contactanos a traves de nuestras Redes Sociales:</span>
          </div>

          <div onClick={handleClick} className="card-share">
            <div id="sociales" className={`social_reveal${social_reveal}`}>
              <a
                className="btn-floating btn-fb waves-effect waves-light btn-sm"
                href="https://www.facebook.com/IplaLosAndes"
                type="button"
                role="button"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn-floating btn-tw waves-effect waves-light btn-sm"
                href="https://twitter.com/iplalosandes"
                type="button"
                role="button"
              >
                <i className="fab fa-twitter"></i>
              </a>
              {/*<a
                className="btn-floating btn-li waves-effect waves-light btn-sm"
                href=""
                type="button"
                role="button"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>*/}
              <a
                className="btn-floating btn-dribbble waves-effect waves-light btn-sm"
                href="https://www.instagram.com/iplalosandes"
                type="button"
                role="button"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>

            <a className="btn-floating btn-action share-toggle indigo ml-auto mr-4 float-right waves-effect waves-light btn-sm">
              <i className="fas fa-share-alt"></i>
            </a>
          </div>

          {/*<div>
          <a href="" className="me-4 footer_redes py-3 px-3">
            <i className="fab fa-facebook-f "></i>
          </a>
          <a href="" className="me-4 footer_redes py-3 px-3">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="" className="me-4 footer_redes py-3 px-3">
            <i className="fab fa-google"></i>
          </a>
          <a href="" className="me-4 footer_redes py-3 px-3">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="" className="me-4 footer_redes py-3 px-3">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="" className="me-4 footer_redes py-3 px-3">
            <i className="fab fa-github"></i>
          </a>
        </div>*/}
        </div>

        <div className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 mx-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3"></i>ipla
                </h6>
                <p>
                  <i className="fas fa-home me-3"></i> Membrillar #360, Los
                  Andes
                </p>
                <NavLink className="color_hover pb-3">
                  <i className="fas fa-envelope me-3"></i>
                  admision@ipla.cl
                </NavLink>
                <p>
                  <i className="fas fa-mobile-alt me-3"></i> +56 99 680 8457
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> +56 34 259 5600
                </p>
              </div>

              <div className="col-md-3 mx-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  Nuestro Instituto
                </h6>
                <p className="color_hover">
                  <a href="/asuntos-estudiantiles" className="text-reset">
                    Asuntos Estudiantiles
                  </a>
                </p>
                <p className="color_hover">
                  <a href="/red-egresados" className="text-reset">
                    Red de Egresados
                  </a>
                </p>
                <p className="color_hover">
                  <a href="/sobre-nosotros" className="text-reset">
                    Sobre Nosotros
                  </a>
                </p>
                <p className="color_hover">
                  <a href="/nuestra-gente" className="text-reset">
                    Nuestra Gente
                  </a>
                </p>
              </div>

              <div className="col-md-3 mx-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Escuelas</h6>
                <p className="color_hover">
                  <a href="/escuela-educacion" className="text-reset">
                    Escuela de Educación y Ciencias Sociales
                  </a>
                </p>
                <p className="color_hover">
                  <a
                    href="/escuela-procesos-industriales"
                    className="text-reset"
                  >
                    Escuela de Procesos Industriales
                  </a>
                </p>
                <p className="color_hover">
                  <a href="#!" className="text-reset">
                    Escuela de Administración y Comercio
                  </a>
                </p>
                <p className="color_hover">
                  <a href="#!" className="text-reset">
                    Escuela de Tecnologia Aplicada
                  </a>
                </p>
              </div>

              <div className="col-md-3 mx-0 mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Mas</h6>
                <p className="color_hover">
                  <a href="/sobre-admision" className="text-reset">
                    Admisión
                  </a>
                </p>

                <p className="color_hover">
                  <a href="/asuntos-estudiantiles" className="text-reset">
                    Asuntos estudiantiles
                  </a>
                </p>

                <p className="color_hover">
                  <a href="/red-egresados" className="text-reset">
                    Red de Egresados
                  </a>
                </p>

                <p className="color_hover">
                  <a
                    href="https://iplacampusvirtual.cl/moodle/"
                    className="text-reset"
                  >
                    Campus Virtual
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 cna">
            <img className="cna-img h-100 ps-3" src={cna} alt=""/>
            <span className="text-dark ms-3 fw-bolder">No Acreditada</span>
          </div>
          <div className="col-md-9">
            <div className="text-center p-4">
              © 2024 - 2026 Copyright{" "}
              <a className="text-reset fw-bold" href="https://www.ipla.cl/">
                IPLA:
              </a>{" "}
              Instituto Profesional Libertador de Los Andes | Todos Los Derechos
              Reservados
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
